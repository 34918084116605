<template>
  <v-card class="pb-6 backgroundColor" height="100%">
    <div
        class="Welcometitle primary--text titleFontlg">
      Nettoyage des manuels
    </div>
      <v-row class="d-flex justify-center pt-10">
        <v-col
          class="ma-0 pa-0"
          cols="10"
          md="6"
        >
        <v-row>
          <v-col cols="12" class="justify-center align-center">
            <NextButton
                width="100%"
                color="primary"
                text="Biblio Manuels"
                icon="mdi-delete"
                @clicked="openApp('bibliomanuels://clean/')"
            />
          </v-col>

          <v-col cols="12">
            <NextButton
                width="100%"
                color="primary"
                text="Lelivrescolaire"
                icon="mdi-delete"
                @clicked="openApp('lls://commands/reset')"
            />
          </v-col>

          <v-col cols="12">
            <NextButton
                width="100%"
                color="primary"
                text="Educadhoc"
                icon="mdi-delete"
                @clicked="openApp('educadhoc://clean')"
            />
          </v-col>

          <v-col cols="12">
            <NextButton
                width="100%"
                color="primary"
                text="Lib manuels"
                icon="mdi-delete"
                @clicked="openApp('libmanuels://reset-app-data')"
            />
          </v-col>

          <v-col cols="12">
            <NextButton
                width="100%"
                color="primary"
                text="Manuel Max"
                icon="mdi-delete"
                @clicked="openApp('https://manuelmax.page.link/?link=https://manuelnumeriquemax.belin.education/reset-app-data&apn=com.humensis.cosmos&ibi=com.editions-belin.cosmos&isi=1526809556&ofl=https://manuelnumeriquemax.belin.education/')"
            />
          </v-col>
        </v-row>
        </v-col>
      </v-row>
  </v-card>
</template>

<script>
import NextButton from "./../components/Utility/NextButton.vue";
export default {
  name: "Clean",
  data: () => ({
    forgetPass: false,
    forgetID: false,
    resetEmailSend: false,
    errorLogin: false,
  }),
  components: {
    NextButton,
  },
  methods: {
    openApp(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
  
<style lang="scss">
@import "@/assets/scss/colors.scss";

.Login-title {
  font-family: "dincondensed",serif;
  font-size: 22px;
  color: $primary;
}
</style>
<style scoped>
.Welcometitle {
  text-align: center;
  margin-bottom: 16px;
  margin-top: 50px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.titleFontlg {
  font-size: 28px;
}
</style>

